import { ko } from 'date-fns/locale'
import { DayPicker } from 'react-day-picker'
import styles from './calendar.module.scss'

const date = new Date(Date.UTC(2023, 5, 10, 9))

const Calendar = () => {
  return (
    <section className={styles.section}>
      <h2>DATE</h2>
      <p className={styles.date}>{new Intl.DateTimeFormat('ko-KR').format(date)} (토) 오후 4시</p>
      <div className={styles.calendar}>
        <div className={styles.month}>{date.getMonth() + 1}월</div>
        <DayPicker
          locale={ko}
          disableNavigation
          defaultMonth={date}
          selected={date}
          modifiersClassNames={{ selected: styles.selected }}
          styles={{
            caption: { display: 'none' },
            head_row: { height: 24 },
            day: { width: 36, height: 36, display: 'flex', justifyContent: 'center', alignItems: 'center' },
          }}
        />
      </div>
    </section>
  )
}

export default Calendar
