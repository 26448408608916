import { MouseEvent } from 'react'
import characterIcon from 'assets/icons/character.png'
import styles from './imageGrid.module.scss'

export interface IImageGridProps {
  imageList: string[]
  handleImageClick?: (e: MouseEvent) => void
  odd?: boolean
}

const ImageGrid = ({ imageList, handleImageClick, odd }: IImageGridProps) => {
  return (
    <ul className={styles.imageList}>
      {imageList.map((image, index) => (
        <li key={image}>
          <button type='button' onClick={handleImageClick}>
            <div className={styles.image} style={{ backgroundImage: `url(${image})` }} data-index={index} />
          </button>
        </li>
      ))}
      {odd && (
        <div className={styles.placeholder}>
          <img src={characterIcon} alt='wedding' />
        </div>
      )}
    </ul>
  )
}

export default ImageGrid
