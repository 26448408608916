import { supabase } from './supabase'
import { type InsertMessageDTO } from 'types/supabase'

export const getMessages = async () => {
  const { data } = await supabase
    .from('wedding_board')
    .select()
    .eq('customer', 'chaechae')
    .order('id', { ascending: false })

  return { messages: data || [] }
}

export const postMessage = async (message: InsertMessageDTO) => {
  const { data, error } = await supabase
    .from('wedding_board')
    .insert({ ...message })
    .select()

  return { message: data || [], error }
}

export const deleteMessage = async (id: number) => {
  const { error } = await supabase.from('wedding_board').delete().eq('id', id)

  return { error }
}
