import { useNavigate } from 'react-router-dom'

import { trackMenuClick } from 'hooks/useGTM'
import Button from 'components/Button'
import styles from './home.module.scss'

const Home = () => {
  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate('/invitation')
    trackMenuClick('invitation')
  }

  return (
    <>
      <div className={styles.mainImage} />
      <div className={styles.bottomSheet}>
        <div className={styles.fadeIn} style={{ animationDelay: '100ms' }}>
          신랑 <span>정민채</span> & 신부 <span>고은채</span>
        </div>
        <p className={styles.fadeIn} style={{ animationDelay: '300ms' }}>
          2023. 6. 10 (토) 오후 4시
          <br />
          아펠가모 공덕 7층 마리에홀
        </p>
        <Button
          className={styles.fadeIn}
          style={{ animationDelay: '500ms' }}
          text='청첩장 보기'
          size='fullWidth'
          onClick={handleButtonClick}
        />
      </div>
    </>
  )
}

export default Home
